"use client";

import React, { useEffect, useState } from "react";
import Dialog from "@/components/ui/Dialog";

import Button from "@/components/ui/Button";

import CloseIcon from "public/close.svg";
import FilterIcon from "public/assets/icons/filter.svg";
import FinanceIcon from "public/assets/icons/finance-tag.svg";
import DepositIcon from "public/assets/icons/deposit-tag.svg";
import GamingIcon from "public/assets/icons/gaming-tag.svg";
import SurveryIcon from "public/assets/icons/survey-tag.svg";
import NewIcon from "public/assets/icons/new-tag.svg";
import { cn } from "@/utils";
import { LANGUAGES_CONTENT } from "@/constants/language";
import { StorageService } from "@/service/StorageService";
import { AndroidService } from "@/service/Android";
import { FILTER_CLICKED, FILTER_UPDATED } from "@/constants/logs";

export default function OfferFilter({ onSubmit, activeTags = [], activeSort }) {
  const [open, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const filterOptions = GET_FILTER_OPTIONS(StorageService.getLanguage());
  const lang = StorageService.getLanguage();

  useEffect(() => {
    let mappedTags = {};
    activeTags.forEach((tag) => {
      const tagGrp = filterOptions.find(
        (group) => !!group.filters.find((fil) => fil.id === tag)
      );
      mappedTags[tagGrp.id] = tag;
    });
    setSelectedFilters(mappedTags);
  }, [activeTags, open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Handle toggling Gaming filter outside the popup
  const handleOutsideGamingToggle = () => {
    const isActive = selectedFilters["category"] === "GAMING";
    const newFilters = isActive
      ? { ...selectedFilters, category: undefined } // Remove Gaming
      : { ...selectedFilters, category: "GAMING" }; // Add Gaming

    AndroidService.logEvent(
      FILTER_CLICKED,
      JSON.stringify({
        filter_opts: isActive ? "GAMING_REMOVED" : "GAMING",
        user_id: StorageService.getUserId(),
      })
    );
    setSelectedFilters(newFilters);

    // Apply filters immediately
    const tags = Object.values(newFilters).filter(Boolean);
    onSubmit(tags);
  };
  // Handle toggling Gaming filter outside the popup
  const handleOutsideOneStepToggle = () => {
    const isActive = selectedFilters["type"] === "SINGLE_STEP";
    const newFilters = isActive
      ? { ...selectedFilters, type: undefined } // Remove one step
      : { ...selectedFilters, type: "SINGLE_STEP" }; // Add one step

    AndroidService.logEvent(
      FILTER_CLICKED,
      JSON.stringify({
        filter_opts: isActive ? "SINGLE_STEP_REMOVED" : "SINGLE_STEP",
        user_id: StorageService.getUserId(),
      })
    );
    setSelectedFilters(newFilters);

    // Apply filters immediately
    const tags = Object.values(newFilters).filter(Boolean);
    onSubmit(tags);
  };
  // Handle toggling New filter outside the popup
  const handleOutsideNewToggle = () => {
    const isActive = selectedFilters["feature"] === "NEW";
    const newFilters = isActive
      ? { ...selectedFilters, feature: undefined } // Remove one step
      : { ...selectedFilters, feature: "NEW" }; // Add one step

    AndroidService.logEvent(
      FILTER_CLICKED,
      JSON.stringify({
        filter_opts: isActive ? "NEW_REMOVED" : "NEW",
        user_id: StorageService.getUserId(),
      })
    );
    setSelectedFilters(newFilters);

    // Apply filters immediately
    const tags = Object.values(newFilters).filter(Boolean);
    onSubmit(tags);
  };

  const handleToggleFilter = ({ groupId, filterId }) => {
    // AndroidService.logEvent(
    //   FILTER_CLICKED,
    //   JSON.stringify({
    //     filter_opts: filterId,
    //     user_id: StorageService.getUserId(),
    //   })
    // );

    if (selectedFilters[groupId] === filterId) {
      setSelectedFilters((prev) => {
        delete prev[groupId];
        return { ...prev };
      });
      return;
    }

    setSelectedFilters((prev) => ({
      ...prev,
      [groupId]: filterId,
    }));
  };

  const handleClear = () => {
    setSelectedFilters({});
  };

  const handleApply = () => {
    const tags = Object.values(selectedFilters);
    if (arraysEqual(tags, activeTags)) {
      handleClose();
      return;
    }
    AndroidService.logEvent(
      FILTER_UPDATED,
      JSON.stringify({
        filter_opts: tags.join(","),
        sort_opts: activeSort || "default", //Sort selected at that moment
        user_id: StorageService.getUserId(),
      })
    );
    onSubmit(tags);
    handleClose();
  };

  const noOfSelectedFilters = Object.keys(selectedFilters).filter(
    (key) => selectedFilters[key]
  ).length;
  const isGamingActive = selectedFilters["category"] === "GAMING";
  const isOneStepActive = selectedFilters["type"] === "SINGLE_STEP";
  const isNewActive = selectedFilters["feature"] === "NEW";

  //if tags are actually applied to the list
  const isTagsActive = activeTags.length > 0;

  return (
    <div>
      <div className="flex items-center gap-1 overflow-auto">
        <div
          className={cn(
            "rounded-full border-[1px] border-accent-color w-fit px-2 py-1 flex items-center gap-1 ",
            isTagsActive && "bg-accent-color border-accent-color"
          )}
          onClick={handleOpen}
        >
          <FilterIcon
            className={cn(isTagsActive ? "text-white" : "text-accent-color")}
          />
          <div className="flex items-center gap-1">
            <p
              className={cn(
                "text-sm ",
                isTagsActive ? "text-white" : "text-accent-color"
              )}
            >
              {
                LANGUAGES_CONTENT[StorageService.getLanguage()].home.filters
                  .title
              }
            </p>
            {isTagsActive && (
              <p className="grid w-4 h-4 text-accent-color rounded-full text-xxs bg-white place-items-center">
                {activeTags.length}
              </p>
            )}
          </div>
        </div>
        {/* Gaming One-Tap Filter */}
        <div
          className={cn(
            "rounded-full border-[1px] border-gray-500 w-fit px-2 py-1 flex items-center gap-1",
            isGamingActive
              ? "bg-accent-color border-accent-color text-white"
              : "text-gray-500"
          )}
          onClick={handleOutsideGamingToggle}
        >
          <p className="select-none text-sm whitespace-nowrap">
            {LANGUAGES_CONTENT[lang].home.tags.GAMING}
          </p>
          {isGamingActive && <CloseIcon className="'text-accent-color" />}
        </div>
        {/* Gaming One-Tap Filter */}
        <div
          className={cn(
            "rounded-full border-[1px] border-gray-500 w-fit px-2 py-1 flex items-center gap-1",
            isOneStepActive
              ? "bg-accent-color border-accent-color text-white"
              : "text-gray-500"
          )}
          onClick={handleOutsideOneStepToggle}
        >
          <p className="select-none text-sm whitespace-nowrap">
            {LANGUAGES_CONTENT[lang].home.tags.SINGLE_STEP}
          </p>
          {isOneStepActive && <CloseIcon className="'text-accent-color" />}
        </div>
        {/* New One-Tap Filter */}
        <div
          className={cn(
            "rounded-full border-[1px] border-gray-500 w-fit px-2 py-1 flex items-center gap-1",
            isNewActive
              ? "bg-accent-color border-accent-color text-white"
              : "text-gray-500"
          )}
          onClick={handleOutsideNewToggle}
        >
          <p className="select-none text-sm whitespace-nowrap">
            {LANGUAGES_CONTENT[lang].home.tags.NEW}
          </p>
          {isNewActive && <CloseIcon className="'text-accent-color" />}
        </div>
      </div>
      <Dialog
        isOpen={open}
        onOpenChange={handleClose}
        position="bottom"
        classes={{ wrapper: "!p-0" }}
        className="border-none rounded-none !p-0 bg-white"
        closable="true"
      >
        <div className="p-4 border-b border-slate-400">
          <p className="font-semibold">
            {LANGUAGES_CONTENT[StorageService.getLanguage()].home.filters.title}{" "}
            {noOfSelectedFilters > 0 && (
              <span className="text-sm font-medium text-accent-color">{`(${noOfSelectedFilters} ${
                LANGUAGES_CONTENT[StorageService.getLanguage()].home.filters
                  .selected
              })`}</span>
            )}
          </p>
        </div>
        <div className="px-6 border-b border-slate-400">
          {filterOptions.map((group) => (
            <FilterGroup
              filterGroup={group}
              key={group.id}
              selectedFilters={selectedFilters}
              handleToggleFilter={handleToggleFilter}
            />
          ))}
        </div>
        <div className="flex items-center justify-between px-6 py-3">
          <Button variant="ghost" className="text-black" onClick={handleClear}>
            {
              LANGUAGES_CONTENT[StorageService.getLanguage()].home.filters
                .clearAll
            }
          </Button>
          <Button className="w-32" variant="solid" onClick={handleApply}>
            {
              LANGUAGES_CONTENT[StorageService.getLanguage()].home.filters
                .applyAction
            }
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

const FilterGroup = ({ filterGroup, selectedFilters, handleToggleFilter }) => {
  const { filters, label, id: groupId } = filterGroup;

  return (
    <div className="flex flex-col gap-3 py-4 border-b border-slate-600">
      <p className="text-slate-600">{label}</p>
      <div className="flex flex-wrap gap-2">
        {filters.map((d) => {
          const isSelected = selectedFilters[groupId] === d.id;
          const Icon = d.icon;
          return (
            <div
              className={cn(
                "p-2 rounded-lg border-x border-y min-w-[120px] flex flex-col items-start gap-2",
                isSelected
                  ? "bg-accent-color border-accent-color"
                  : "border-slate-600 "
              )}
              onClick={() => {
                handleToggleFilter({ groupId, filterId: d.id });
              }}
            >
              {d.icon && (
                <Icon
                  className={cn(
                    "text-lg",
                    isSelected ? "text-white" : "text-slate-600"
                  )}
                />
              )}
              <p
                className={cn(
                  "select-none text-sm",
                  isSelected ? "text-white" : "text-slate-600"
                )}
              >
                {d.label}
              </p>
              {/* {isSelected && <CloseIcon className="text-sm text-white" />} */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const GET_FILTER_OPTIONS = (lang = "ENGLISH") => [
  {
    id: "type",
    label: LANGUAGES_CONTENT[lang].home.filters.type,
    filters: [
      {
        id: "SINGLE_STEP",
        label: LANGUAGES_CONTENT[lang].home.tags.SINGLE_STEP,
      },
      {
        id: "MULTI_REWARD",
        label: LANGUAGES_CONTENT[lang].home.tags.MULTI_REWARD,
      },
    ],
  },
  {
    id: "category",
    label: LANGUAGES_CONTENT[lang].home.filters.category,
    filters: [
      {
        id: "GAMING",
        label: LANGUAGES_CONTENT[lang].home.tags.GAMING,
        icon: GamingIcon,
      },
      {
        id: "FINANCE",
        label: LANGUAGES_CONTENT[lang].home.tags.FINANCE,
        icon: FinanceIcon,
      },
      {
        id: "SURVEY",
        label: LANGUAGES_CONTENT[lang].home.tags.SURVEY,
        icon: SurveryIcon,
      },
    ],
  },
  {
    id: "feature",
    label: LANGUAGES_CONTENT[lang].home.filters.features,
    filters: [
      {
        id: "DEPOSIT",
        label: LANGUAGES_CONTENT[lang].home.tags.DEPOSIT,
        icon: DepositIcon,
      },
      {
        id: "NEW",
        label: LANGUAGES_CONTENT[lang].home.tags.NEW,
        icon: NewIcon,
      },
    ],
  },
];

function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}
