import React from "react";
import Image from "next/image";
import EmptyListImage from "/public/assets/images/empty.png";
import { cn } from "@/utils";
import Button from "../ui/Button";

const NoDataFound = ({
  emptyMessage,
  messageClass,
  cta,
  onCtaClick = () => {},
}) => {
  return (
    <div className="flex flex-col items-center gap-4">
      <Image
        src={EmptyListImage}
        width={cta ? 100 : 150}
        height={cta ? 100 : 150}
        alt="No data found"
        className="!relative !block"
      />
      <p
        className={cn(
          "text-xl capitalize font-semibold text-slate-400",
          messageClass
        )}
      >
        {emptyMessage}
      </p>
      {cta && (
        <Button
          className="bg-gradient-to-br from-accent-action-dark to-accent-action-light"
          onClick={onCtaClick}
        >
          {cta}
        </Button>
      )}
    </div>
  );
};

export default NoDataFound;
